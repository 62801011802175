import React from "react"
import SocialLinks from './social-links'

const Footer = () => (
  <>
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6 subscribe-footer">
          <p>Join our mailing list to receive the latest news and updates from F+S.</p>
          <form action="//forms-surfaces.us1.list-manage.com/subscribe/post?u=deed5374c301844ccef9b55ac&amp;id=44315d1845" className="validate" id="mc-embedded-subscribe-form" method="post" name="mc-embedded-subscribe-form" noValidate="" rel="noreferrer" target="_blank">
            <div id="mc_embed_signup_scroll">
              <input className="email" id="mce-EMAIL" name="EMAIL" placeholder="email address" required="" type="email" defaultValue="" />
              <div aria-hidden="true" style={{'position': 'absolute', 'left': '-5000px'}}>
                <input name="b_deed5374c301844ccef9b55ac_3e2c17d270" tabIndex="-1" type="text" readOnly value="" />
                <input type="checkbox" value="2" name="group[21][2]" id="mce-group[21]-21-0" readOnly checked="checked" />
                <input type="checkbox" value="1" name="group[21][1]" id="mce-group[21]-21-1" readOnly checked="checked" />
              </div>
              <div className="clear">
                <input className="button" id="mc-embedded-subscribe" name="subscribe" type="submit" value="Subscribe" />
              </div>
            </div>
         </form>
        </div>
        <div className="col-md-2 col-sm-6">
          <div className="footer_link" id="footer-contact-4">
            <h4>USA</h4>
            <p>1025 N 71st Avenue<br />
            Phoenix, AZ 85043
            </p>
            <a href="tel:8004510410">T 800.451.0410</a>
            <a href="tel:4127819003">T 412.781.9003</a>
            <a href="tel:4123854715">F 412.385.4715</a>
            <a href="https://www.forms-surfaces.com" rel="noreferrer" target="_blank">www.forms-surfaces.com</a>
          </div>
        </div>
        <div className="col-md-2 col-sm-6">
          <div className="footer_link" id="footer-contact-2">
            <h4>MIDDLE EAST</h4>
            <p>
              Suite B 307, Dubai Silicon Oasis<br />
              PO Box 341052, Dubai, UAE <br />
            </p>
            <a href="tel:+97145015605">T +971 4 501 5605</a>
            <a href="tel:+97145015606">F +971 4 501 5606</a>
            <a href="https://www.forms-surfaces.com" rel="noreferrer" target="_blank">www.forms-surfaces.com</a>
          </div>
        </div>
        <div className="col-md-2 col-sm-6">
          <div className="footer_link" id="footer-contact-1">
            <h4>INDIA</h4>
            <p>
              Ofﬁce No. 104, Pentagon 4,<br />
              Magarpatta City, Hadapsar,<br />
              Pune 411028, MH, India
            </p>
            <a href="tel:+912067482400">T +91 20 67482400</a>
            <a href="https://www.forms-surfaces.co.in" rel="noreferrer" target="_blank">www.forms-surfaces.co.in</a>
          </div>
        </div>
        <div className="col-md-2 col-sm-6">
          <div className="footer_link" id="footer-contact-1">
            <h4>ASIA</h4>
            <p>
              60 Kaki Bukit Place,<br />
              #02-10, Eunos Tech Park,<br />
              Singapore 415979
            </p>
            <a href="tel:+6567488383">T +65 6748 8383</a>
            <a href="tel:+6564411185">F +65 6441 1185</a>
            <a href="https://www.forms-surfaces.co.in" rel="noreferrer" target="_blank">www.forms-surfaces.co.in</a>
          </div>
        </div>
      </div>
      <SocialLinks />
      <div className="footer_website">
        <div className="copyright">© {new Date().getFullYear()} Forms+Surfaces</div>
        <div><a id="contact-website2" href="https://www.forms-surfaces.com" rel="noreferrer" target="_blank">www.forms-surfaces.com</a></div>
      </div>
    </div>
  </footer>
  <div className="slider_block section_first_slider HOSPITALITY RETAIL COMMERCIAL OFFICEBUILDING REALESTATE HEALTHCARE EDUCATION MALLS">
    <div className="multiple-items">
      <div className="slider_one multiplesliderbottom-0 HOSPITALITY"></div>
    </div>
  </div>
  <div className="slider_block sec_slider ELEVATORHANDRAILS EXTENSIVEMATERIALOPTIONS LIGHTPLANELEDPANELS ELEVATORCEILINGS ELEVATORDOORS">
    <div className="multiple-items">
      <div className="slider_one sectwomultiplesliderbottom-0  "></div>
    </div>
  </div>
  <div className="slider_block third_slider COMPREHENSIVEDELIVERABLES IN-APPBUDGETPRICING TIME-SAVINGEASY-TO-USE HIGH-QUALITYVISUALRENDERINGS">
    <div className="multiple-items">
      <div className="slider_one secthreemultiplesliderbottom-0  "></div>
    </div>
  </div>
  <div className="slider_block fourth_slider 50YEARSOFEXPERTISE DESIGNEDINTHEUSA ENGINEEREDMANUFACTUREDININDIA COMPREHENSIVESUPPORT GLOBALLOGISTICS">
    <div className="multiple-items">
      <div className="slider_one secfourmultiplesliderbottom-0  "></div>
    </div>
  </div>
  <div className="slider_block fifth_slider">
    <div className="multiple-items">
      <div className="slider_one secfivemultiplesliderbottom-0  "></div>
    </div>
  </div>
  </>
)


export default Footer
