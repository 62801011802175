import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player/lazy'
import { StaticImage, getImage } from 'gatsby-plugin-image'
import { navigate } from "@reach/router"

class SliderSectionInner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      data: props.data,
      textExpanded: true
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if(this.props.showContent !== prevProps.showContent && this.state.textExpanded){
      setTimeout(function(){
        this.setState({
          textExpanded: false
        })
      }.bind(this), 4000)
    }
  }

  collapseText() {

  }

  goToContact() {
    this.props.toggleClose()
    navigate('#contact-us')
  }

  render(){
    let leftImage = this.state.data.Images.find((object)=>{
      return object.Location === 'bottom-left'
    })
    let middleImage = this.state.data.Images.find((object)=>{
      return object.Location === 'middle'
    })
    let rightImage = this.state.data.Images.find((object)=>{
      return object.Location === 'right'
    })

    let rightImageObject = null
    if(rightImage){
      rightImageObject = <img src={rightImage.Image.src.publicURL} alt={this.state.title}/>
    }


    let sliderInnerClass = 'slider_inner'
    let toggleTextButton = null
    if(this.state.data.style === 'simple'){
      sliderInnerClass = 'slider_inner simple'
      toggleTextButton = <button className="toggle-text-button" onClick={(e) => this.setState({
        textExpanded: !this.state.textExpanded
      })}>{this.state.textExpanded ? '-' : '+'}</button>
    }

    let video = this.state.data.video
    if(video){
      sliderInnerClass += ' video'
    }

    if(this.state.textExpanded){
      sliderInnerClass += ' expanded'
    }
    let title = this.state.title
    if(title.includes('LEVEL')){
      title = <span>{title}</span>
    }
    return(
      <>
      <div className="close_popup_overlay"
      onClick={(e) => {this.props.toggleClose()}}>
      </div>
      <div className="close_popup" onClick={(e) => {this.props.toggleClose()}}>
       <span>✕</span>
      </div>
      <div className={sliderInnerClass}>
         <div className="slider_part_left">
            {toggleTextButton}
            <div className="slider_info">
               <div className="slider_info_title">
               <h3>{title}</h3>
               </div>
               <div className="slider_info_text">
                 <h4>{this.state.data.SubTitle}</h4>
                 <h4>{this.state.data.ThirdTitle}</h4>
                 <div dangerouslySetInnerHTML={{__html: this.state.data.Caption}} />
                 <div className="want-more-bottom">
                    {this.state.data.links.map((object, i)=>{
                      let className = 'red-link'
                      if(i > 0){
                        className = 'green-link'
                      }
                      if(object.style){
                        className += ' ' + object.style
                      }
                      let link = <a href={object.url} target="_blank" tabIndex={i}>{object.text}</a>
                      if(object.url === '#contact-us'){
                        link = <button onClick={(e) => this.goToContact()}>{object.text}</button>
                      }
                      return (
                        <div className={className} key={i}>
                          {link}
                        </div>
                      )
                    })}
                 </div>
               </div>
            </div>
            <div className="slider_img_group">
              {leftImage && leftImage?.Image &&
                <div className="slider_imgs_row">
                 <img className="border_right_none" src={leftImage.Image} alt="slider-img"/>
                </div>
              }
              {middleImage && middleImage?.Image &&
                <div className="slider_imgs_row">
                 <img className="border_right_none" src={middleImage.Image} alt="slider-img"/>
                </div>
              }

            </div>
         </div>
         {rightImage && rightImage.Image &&
          <div className="slider_large_img">
           {rightImageObject}
          </div>
         }
         {video && this.props.showContent &&
           <div className="slider_large_video">
           <ReactPlayer url={video} width="100%" height="100%" playsinline={true} muted={true} playing={true} controls={true} loop={false} />
           </div>
         }

      </div>
      </>
    )
  }
}

export default SliderSectionInner
