import postalCode from '../../data/geo-postal-code.json'
import geoState from '../../data/geo-state.json'
import geoCountry from '../../data/geo-country.json'
import territoryGeography from '../../data/sales-territory-geography.json'
import territoryAssignment from '../../data/sales-territory-assignment.json'
import salesTerritory from '../../data/sales-territory.json'
import employee from '../../data/employee.json'

export const territoryLookup = (searchText) => {
  searchText = searchText.toLowerCase();
  var returnObject = null;
  var foundZip = postalCode.find(
    item => String(item.PostalCode).toLowerCase().indexOf(searchText) > -1
  );

  if(foundZip){
    returnObject = foundZip;
  }else{
    let foundState = geoState.find(item => String(item.Name).toLowerCase().indexOf(searchText) > -1);
    if(foundState){
      returnObject = foundState;
    }else{
      let foundCountry = geoCountry.find(item => String(item.Name).toLowerCase().indexOf(searchText) > -1);
      if(foundCountry){
        returnObject = foundCountry;
      }
    }
  }

  var foundTerritoryGeography = territoryGeography.find((object)=>{
    if(returnObject.PostalCode){
      return object.GeoPostalCodeId === returnObject.Id;
    }
    if(returnObject.StateCode){
      return object.GeoStateId === returnObject.Id;
    }
    if(returnObject.TerritoryAssignment === 'Country'){
      return object.GeoCountyId === returnObject.Id;
    }
  })

  if(foundTerritoryGeography){
    returnObject = foundTerritoryGeography
    var foundTerritoryAssignment = territoryAssignment.find(item => {
      return(item.SalesTerritoryAssignmentTypeId === 1 &&
        (item.EndDate === '' || item.EndDate === 'NULL') &&
        item.SalesTerritoryId === foundTerritoryGeography.SalesTerritoryId
      );
    })
    if(foundTerritoryAssignment){
      returnObject = foundTerritoryAssignment;
      let foundTerritory = salesTerritory.find(item =>
        item.Id === foundTerritoryAssignment.SalesTerritoryId);
      if(foundTerritory){
        var foundEmployee = employee.find(item => item.Id === foundTerritory.CurrentManagerId);
        if(foundEmployee){
          returnObject = foundEmployee;
          returnObject.SalesTerritoryDivisionName = foundTerritory.SalesTerritoryDivisionName;
        }
      }
    }
  }
  if(returnObject.PrimaryEmail){
    return returnObject;
  }else{
    return null;
  }
}
