import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import SocialLinks from './social-links'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import otisLogo from '../img/otis-hp/OTIS-Logo.svg'
import * as styles from './slider-hero.module.css'

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
  dots: true,
  lazyLoad: true,
  autoplay: 1,
  speed: 1000,
  autoplaySpeed: 5000,
  pauseOnHover: false
}
class SliderHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slides: props.data,
      activeSlide: 0
    }
  }

  componentDidMount() {

  }

  component

  render(){
    return(
      <div className='slider-hero'>
        <Slider ref={slider => (this.slider = slider)} {...settings}
        afterChange={(e)=>{this.setState({activeSlide:e})}} fade={true} appendDots={(dots)=> {return (<ol>{dots}</ol>)}}>
          {this.state.slides.map((slide,i) => {
            const image = getImage(slide.image.src)
            if(image){
              return(<GatsbyImage key={i} image={image} alt={slide.title} />)
            }else{
              return null
            }
          })}
        </Slider>
        <div className='elevator_block'>
          <div className='elevator_inner'>
            <div className='elevation_info'>
              <h1>Forms+Surfaces Elevator Interiors</h1>
              <p>
              Comprehensive Systems with<br/>Versatile Real-World Applicability
              </p>
              <span>
                <a href={"#" + this.props.anchor}>LEARN MORE</a>
              </span>
              <div style={{textTransform: 'uppercase', fontSize: '2rem', display: 'flex', justifyContent: 'bottom', padding: '2rem 0', 'alignItems': 'flex-end'}}>
              {this.props.contentVersion === "otis" &&
                    <p style={{
                      fontSize: '16px'
                    }}>
                    In Partnership With <img style={{
                      'display':'inline-block',
                      'width': '140px',
                      'marginLeft': '2rem'
                    }}
                    src={otisLogo} />
                    </p>
              }
              </div>
            </div>
            <div className='retail_info'>
              <h2>{this.state.slides[this.state.activeSlide].title}</h2>
              <p>{this.state.slides[this.state.activeSlide].paragraph}</p>
            </div>
          </div>
        </div>
        <div className='copyright'>
          © {new Date().getFullYear()} Forms+Surfaces
        </div>
        <SocialLinks className='social_list' />
      </div>
    )
  }
}
export default SliderHero
