import React from "react"
import {
  Nav,
  Navbar,
  Button,
  Dropdown,
  Modal
} from 'react-bootstrap'
import fsLogo from '../images/fs_logo.svg'


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  handleOpen = (e) => {
    e.preventDefault()
    this.setState({
      show: true
    })
  }
  handleClose = () => {
    this.setState({
      show: false
    })
  }

  render(){
    return(
      <>
      <Navbar fixed='top' className="top_header_inner">
        <Navbar.Brand style={{
          height: '100%',
        }} className="logo">
          <a id="contact-website-header" href="https://www.forms-surfaces.com/elevator-interiors" rel="noreferrer" target="_blank">
            <img src={fsLogo} alt="Forms+Surfaces" />
          </a>
        </Navbar.Brand>
        <Nav className="align-right cont_btn">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              RESOURCES
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props?.contentVersion === "otis" &&
                <Dropdown.Item href="/pdf/LEVELeElevatorInteriors_Otis.pdf" target="_blank">Elevator Interiors Catalog</Dropdown.Item>
              }
              {this.props?.contentVersion !== "otis" &&
                <Dropdown.Item href="/pdf/Forms+Surfaces_Elevators.pdf" target="_blank">Elevator Interiors Catalog</Dropdown.Item>
              }
              <Dropdown.Item href="/pdf/LEVELe-Elevator-Installation-Instructions.pdf" target="_blank">Installation Instructions</Dropdown.Item>
              <Dropdown.Item href="https://vimeo.com/617229060" onClick={this.handleOpen}>Installation Video</Dropdown.Item>
              <Dropdown.Item href="https://elevatordesignstudio.com" target="_blank">Elevator Design Studio</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button className='popup_btn bg_color_btn' href='#contact-us'>CONTACT US</Button>
        </Nav>
      </Navbar>
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Installation Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/617229060?badge=0&autoplay=1&loop=1" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>
        </Modal.Body>
      </Modal>
      </>
    )
  }
}

export default Header
