import axios from 'axios'
const restPath = process.env.GATSBY_APIURL

export const getTerritoryManager = (searchText) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(restPath + '/tm-search', {
          searchText: searchText
        },
        {headers: {'Content-Type': 'application/json'}})
        .then(function(data) {
          if(data.data){
            if(data.data.errorMessage){
              let dataError = new Error(data.data.errorMessage)
              reject(dataError)
            }else{
              let findOP = data.data.find((item)=>{
                return item.DivisionName === 'Architectural Product'
              })
              resolve(findOP)
            }
          }else{
            let dataError = new Error('Malformed Response')
            reject(dataError)
          }
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
