import * as React from "react"
import { useState, useEffect } from "react"
import Layout from '../components/layout'
import SliderHero from '../components/slider-hero'
import { graphql } from 'gatsby'
import ContactOtis from '../components/contact-otis'
import Contact from '../components/contact'
import SliderSection from '../components/slider-section'
import * as queryString from "query-string"

const IndexPage = ({ data, location }) => {
  let sections = 0
  const params = queryString.parse(location.search)
  let defaultVersion = "us"
  let overrideVersion = null
  if(params?.version){
    defaultVersion = params?.version
    overrideVersion = params?.version
  }
  const [contentVersion, setContentVersion] = useState(defaultVersion)
  const isSSR = typeof window === "undefined"
  const [ipData, setIpData] = useState()
  const [geoData, setGeoData] = useState()
  useEffect(()=>{

    const dataFetch = async () =>{
      const data = await (
        await fetch('https://api.ipify.org?format=json')
      ).json();
      setIpData(data);
    }
    if(!ipData){
      dataFetch()
    }
    const geoFetch = async () =>{
      var geoIPPath = 'https://api.ipdata.co/'+ipData?.ip+'?api-key=8811fd32f36b02794d756b88fdd279c7580a489d0b962e3c48a0a6f3';
      const geoip = await (
        await fetch(geoIPPath)
        ).json();
      switch(geoip?.country_code?.toLowerCase()) {
        case "in":
          setContentVersion("in")
        case "us":
          setContentVersion("us")
        default:
          setContentVersion("me")
      }
      setGeoData(geoip)
    }
    if(ipData && !geoData){
      geoFetch()
    }
  })

  let content = data.allDataJson.edges?.find(item=>{
    if(!overrideVersion){
      return item.node.contentVersion === contentVersion
    }else{
      console.log(overrideVersion)
      return item.node.contentVersion === overrideVersion
    }
  })?.node
  return (
     <Layout contentVersion={contentVersion} >
      {content &&
        <>
             <SliderHero data={content.Hero_Slider} contentVersion={contentVersion} anchor={content?.sections[0]?.Anchor} />
             {content.sections.map((entry, i) => {
               let className = ''
               if(i % 2){
                 className='menus-left'
               }
               let showTouchIcon = false
               if(sections === 0){
                 showTouchIcon = true
               }
               sections++
               return(<SliderSection key={i} data={entry} id={entry.Anchor} className={className} showTouchIcon={showTouchIcon} />)
             })
             }
        </>
      }
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query MyQuery {
  allDataJson {
    edges {
      node {
        contentVersion
        Hero_Slider {
          title
          image {
            extension
            src {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
          paragraph
        }
        sections {
          Anchor
          SubTitle
          Title
          Slides {
            Caption
            Image {
              src {
                childImageSharp {
                  gatsbyImageData( placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
                }
              }
            }
            Spotlight {
              video
              style
              links {
                text
                url
                style
              }
              Title
              ThirdTitle
              SubTitle
              Images {
                Location
                Image {
                  src{
                    publicURL
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
                    }
                  }
                }
              }
              Caption
            }
            Title
          }
        }
      }
    }
  }
}
`
