import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const SocialLinks = ({ className }) => (
  <div className={className ? className: 'footer_socisls'}>
    <ul>
      <li>
        <a href="https://www.facebook.com/FormsandSurfaces/" rel="noreferrer" target="_blank" rel="nofollow"><FontAwesomeIcon icon={faFacebookF} /></a>
      </li>
      <li>
        <a href="https://twitter.com/forms_surfaces" rel="noreferrer" target="_blank" rel="nofollow"><FontAwesomeIcon icon={faTwitter} /></a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/forms-surfaces/" rel="noreferrer" target="_blank" rel="nofollow"><FontAwesomeIcon icon={faLinkedinIn} /></a>
      </li>
      <li>
        <a href="https://www.instagram.com/forms_surfaces/" rel="noreferrer" target="_blank" rel="nofollow"><FontAwesomeIcon icon={faInstagram} /></a>
      </li>
    </ul>
  </div>
)


export default SocialLinks
