import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ScrollToTop from './scrolltotop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SliderSectionInner from './slider-section-inner'
const isSSR = typeof window === "undefined"

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  )
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  )
}

class SliderSection extends React.Component {
  constructor(props) {
    super(props)
    let showTouchIcon = props.showTouchIcon
    this.state = {
      slides: props.data.Slides || [],
      title: props.data.Title,
      subTitle: props.data.SubTitle,
      activeSlide: 0,
      showInnerSlider: false,
      showTouchIcon: showTouchIcon
    }
  }

  componentDidMount() {

  }

  toggleClose = () => {
    this.setState({
      showInnerSlider: false
    })
  }

  render(){
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      infinite: true,
      lazyLoad: true,
      className: "center",
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) => {
        this.setState({ activeSlide: next }, function(){
          this.slider2.slickGoTo(next)
        }.bind(this))
      },
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            infinite: true
          }
        }
      ]
    }
    const innerSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      lazyLoad: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (current, next) => {
        this.setState({ activeSlide: next }, function(){
          this.slider.slickGoTo(next)
        }.bind(this))
      }
    }
    if(isSSR){
      return(<section className={'slider-section section_two social-animate '+this.props.className} id={this.props.id}></section>)
    }else{
      return(
        <>
        <section className={'slider-section section_two social-animate '+this.props.className} id={this.props.id}>
           <div className="container">
              <div className="row">
                 <div className="col-md-3 col-sm-3 col-xs-12"></div>
                 <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className="versatime_info">
                       <h3>{this.state.title}</h3>
                       <p className="subtitle">{this.state.subTitle}</p>
                    </div>
                 </div>
              </div>
           </div>
           <div className="slider_block_two">
             <Slider {...settings} ref={slider => (this.slider = slider)}>
               {this.state.slides.map((object, i) => {
                 const image = getImage(object.Image.src)
                 let slideImageObject = <GatsbyImage image={image} alt={object.Caption} />
                 return (<div key={i} onClick={ e => this.setState({showInnerSlider: true, showTouchIcon: false}) }>
                 {slideImageObject}
                 <div className="info-button"></div>
                 <p><b>{i+1} / {this.state.slides.length} |</b> <span dangerouslySetInnerHTML={{__html: object.Caption}} /></p>
                 {this.state.showTouchIcon &&
                   <div className="touch-icon">
                    <div className="waves" />
                    <div className="hand" />
                   </div>
                 }
                 </div>)
               })}
             </Slider>
              <div className="slider_navigation text-left">
                 <ul>
                   {this.state.slides.map((object, i) => {
                     let activeClass = ''
                     if(i === this.state.activeSlide){
                       activeClass = 'activeSlide'
                     }
                     return (<li key={i} className={activeClass} onClick={e => this.slider.slickGoTo(i)}>{object.Title}</li>)
                   })}
                 </ul>
              </div>
           </div>
           <ScrollToTop />
        </section>
        <div className={'hidden-slider ' + (this.state.showInnerSlider ? 'show' : '')}>
          <Slider {...innerSettings} ref={slider => (this.slider2 = slider)}>
            {this.state.slides.map((object, i) => {
              return(<SliderSectionInner key={i} title={object.Title} data={object.Spotlight} toggleClose={this.toggleClose} showContent={this.state.showInnerSlider && (this.state.activeSlide === i)}/>)
            })}
          </Slider>
        </div>
        </>
      )
    }
  }
}
export default SliderSection
