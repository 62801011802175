import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ScrollToTop from './scrolltotop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const Contact = () => (
  <section className="contact_us_form_block" id="contact-us">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="contact_us_popup">
            <div className="contact_us_popup_info">
              <div className="popup_header">
                <h2>Interested in our Elevator Interiors solutions?</h2>
                <p>Connect with a local Forms+Surfaces team member</p>
              </div>
              <div className="contact_us_body">
                <div className="contact_us_address">
                  <p>
                    Our Territory Managers are ready to assist you with your
                    projects. Fill out the form to right and we will get back
                    to you ASAP.
                  </p>
                  <p>
                    -OR- connect with us directly:
                  </p>
                  <b id="contact-name">Forms+Surfaces</b>
                  <p id="contact-title">US Headquarters</p>
                  <p>
                    <a id="contact-phone" href="tel:+1 800 4510410">T +1 800 4510410</a>
                    <a id="contact-mobile" href="tel:+1 412 7819003">M +1 412 7819003</a>
                    <a id="contact-fax" href="tel:+1 412 7817840">F +1 412 7817840</a>
                    <a id="contact-email" href="mailto:sales@forms-surfaces.com">sales@forms-surfaces.com</a>
                    <a id="contact-website" href="https://www.forms-surfaces.com/?utm_source=microsite&utm_content=112019-fs-logo&utm_campaign=us-elevator-interiors&azure=b" rel="noreferrer" target="_blank">www.forms-surfaces.com</a>
                  </p>
                  <p className="newest_addition">
                    Click on the link to download the newest edition of our
                    Elevator Interiors catalog, featuring 80 pages of
                    application photos showing our products in real-world
                    settings.
                  </p>
                  <div className="pdf_download">
                    <p>
                      <a href="/pdf/Forms+Surfaces_Elevators.pdf"
                       rel="noreferrer"
                       download
                        target="_blank">
                        <FontAwesomeIcon icon={faFilePdf} style={{
                          fontSize: '45px',
                          marginRight: '2rem'
                        }}/>
                        <span>DOWNLOAD ELEVATOR INTERIORS CATALOG</span></a>
                    </p>
                  </div>
                </div>
                <div className="contact_Us_form">
                  <p>
                    Provide us with your information and details and we will
                    get back to you ASAP.
                  </p>
                  <div className="">
                    <form id="contact-form" className="contact_us_fields" action="https://api.forms-surfaces.net/v1/contact" method="post">
                      <input type="text" id="contact-form-first-name" name="contact-last-name" placeholder="First Name" />
                      <input type="text" id="contact-form-last-name" name="contact-last-name" placeholder="Last Name" />
                      <input type="text" id="contact-form-firm-name" name="contact-firm-name" placeholder="Firm Name" />
                      <input type="text" id="contact-form-title-name" name="contact-title-name" placeholder="Title Name" />
                      <input type="email" id="contact-form-email" name="contact-email" placeholder="Email" />
                      <input type="phone" id="contact-form-phone" name="contact-phone" placeholder="Phone" />
                      <input type="text" id="contact-form-country" name="contact-country" placeholder="Country" />
                      <input type="text" id="contact-form-zipcode" name="contact-zipcode" placeholder="Zip / Postal Code" />
                      <input type="hidden" id="contact-form-ceudeliveryformat" name="contact-ceudeliveryformat" value="elevator-interiors-campaign" />
                      <textarea placeholder="Tell us more" id="contact-form-comments" name="contact-comments"></textarea>
                      <div className="submit-recaptcha">
                        <div className="g-recaptcha" data-sitekey="6LftU7AUAAAAAPr_5iWAbiYmsEyzfo2RF79_L_WB"></div>
                        <div className="submit_Btn">
                          <input
                            type="submit"
                            value="SUBMIT INFORMATION"
                            id="Submit_info"
                          />
                        </div>
                      </div>
                      <div id="form-error">
                      </div>
                    </form>
                    <div className="card-back">
                      <div className="back-container">
                        <h2>Thanks!</h2>
                        <p>We received your information.</p>
                      </div>
                    </div>
                  </div>
                  <p className="newest_addition for_mobileV">
                    Click on the link to download the newest edition of our
                    Elevator Interiors catalog, featuring 80 pages of
                    application photos showing our products in real-world
                    settings.
                  </p>
                  <div className="pdf_download for_mobileV">
                    <p>
                      <a href="/pdf/Forms+Surfaces_Elevators.pdf"
                        download
                       rel="noreferrer"
                        target="_blank"><i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                        DOWNLOAD ELEVATOR INTERIORS CATALOG</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </div>
  </section>
)


export default Contact
