import PropTypes from "prop-types"
import React from "react"
import axios from 'axios'
import { territoryLookup } from '../utils/zip-lookup'
import { getTerritoryManager } from '../utils/q'

class TerritoryLookup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      foundContact: null,
      loading: false
    }
  }

  componentDidMount() {

  }

  updateSearchValue = (e) => {
    this.setState({
      searchValue: e.target.value
    })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    let contactData = await getTerritoryManager(this.state.searchValue)
    if(contactData?.Id || contactData?.SalesTerritoryId){
      this.setState({
        foundContact: contactData,
        loading: false
      })
    }
  }

  render(){
    let position = 'Territory Manager, Architectural Products'
    let fullName = this.state?.foundContact?.Territory
    if(fullName){
      fullName = fullName.match(/\(([^)]+)\)/)[1]
    }
    if(this.state?.foundContact?.OverridePosition){
      position = this.state.foundContact.OverridePosition
    }
    let phoneLink = this.state.foundContact?.PrimaryPhone.replace('.','')
    let phoneVal = this.state.foundContact?.PrimaryPhone
    let primaryEmail = this.state.foundContact?.Email
    if(primaryEmail){
      primaryEmail = primaryEmail.toLowerCase()
    }

    return(
      <div>
        <form id="territory-lookup" className="contact_us_fields" onSubmit={(e) => this.onSubmit(e)}>
          <input type="text" id="territory-search" name="territory-search"
          onChange={(e)=>{this.updateSearchValue(e)}}
          placeholder="ENTER ZIP CODE" />
          <input
            type="submit"
            className="submit-dark"
            value="SUBMIT"
            id="submit-territory-lookup"
          />
        </form>
        <div id="form-error">
        </div>
        {this.state.loading &&
          <div
            style={{
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
            alignItems:'center',
            justifyContent: 'center',
            minHeight: '10rem'
            }}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div>Getting Territory Information...</div>
          </div>
        }
        {this.state.foundContact && !this.state.loading &&
          <div className="territory-info">
            <h4>{fullName}</h4>
            <p>{position}</p>
            <p className="territory-info-contact">
              <a id="territory-contact-phone" href="tel:+1 800 4510410">T 800.451.0410</a>
              <a id="territory-contact-mobile" href={'tel:+1 '+phoneLink}>M {phoneVal}</a>
              <a id="territory-contact-email" href={'mailto:'+primaryEmail}>{primaryEmail}</a>
              <a id="territory-contact-website" href="https://www.forms-surfaces.com/?utm_source=microsite&utm_content=112019-fs-logo&utm_campaign=us-elevator-interiors&azure=b" rel="noreferrer" target="_blank">www.forms-surfaces.com</a>
            </p>
          </div>
        }
      </div>
    )
  }
}
export default TerritoryLookup
