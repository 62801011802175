import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

const ScrollToTop = () => (
  <div className="Downtotop">
    <a href="#top"><FontAwesomeIcon icon={faAngleUp} /></a>
  </div>
)


export default ScrollToTop
